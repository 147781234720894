import { virtuosisInsance } from 'utils/axios';

export const getSubscriptionStatus = async (
  userId: string | undefined,
  tenantId: string | undefined,
  ssoToken: string | undefined,
  accessToken: string | undefined,
  refreshToken: string | undefined,
  abortSignal?: AbortSignal,
) => {
  if (!userId) {
    throw new Error('no userId provided');
  }

  if (!tenantId) {
    throw new Error('no tenantId provided');
  }

  if (!ssoToken) {
    throw new Error('no ssoToken provided');
  }

  if (!accessToken) {
    throw new Error('no accessToken provided');
  }

  if (!refreshToken) {
    throw new Error('no refreshToken provided');
  }

  const { data } = await virtuosisInsance.post(
    '/subscription/v1.0/status/microsoft',
    {
      userId,
      tenantId,
      accessToken,
      refreshToken,
      isTeamsApp: true,
    },
    {
      headers: { Authorization: ssoToken },
      signal: abortSignal,
    },
  );

  const { hasLicence, hasConsented } = data || {};

  return {
    hasLicence: !!hasLicence,
    hasConsented: !!hasConsented,
  };
};
