import { useRef, useState, useEffect, useCallback } from 'react';
import * as Sentry from "@sentry/react";

import { Radio } from 'components/Interactives';
import { useTeams } from 'context';

import { getAccount } from 'utils/account';
import { Tokens, authClient } from 'utils/tokens';
import { autoJoinPopUpConfig } from 'utils/consent';
import { updateProcessingStatus } from 'utils/processing';
import { getSSOToken, launchConsentPopup } from 'utils/teams';
import { enableAutoJoin, disableAutoJoin } from 'utils/autoJoin';

const NoTokensProvidedError = new Error('no tokens provided');

const Settings = () => {
  const { teams } = useTeams();

  const autoJoinToggleRef = useRef<HTMLInputElement>(null);

  const processingToggleRef = useRef<HTMLInputElement>(null);

  const [autoJoinStatus, setAutoJoinStatus] = useState<boolean | null>(null);

  const [processingStatus, setProcessingStatus] = useState<boolean | null>(null);

  const handleOnLoad = useCallback(async () => {
    if (teams) {
      try {
        const ssoToken = await getSSOToken();

        if (ssoToken) {
          const {
            HasAutoJoinEnabled,
            HasProcessingEnabled,
          } = await getAccount(ssoToken);

          setAutoJoinStatus(HasAutoJoinEnabled);

          setProcessingStatus(HasProcessingEnabled);
        }
      } catch (err) {
        Sentry.captureException(err);
      }
    }
  }, [teams]);

  const handleToggle = useCallback(async (
    isChecked: boolean,
    toggleRef: React.RefObject<HTMLInputElement>,
    callback: () => Promise<void>,
  ) => {
    if (!toggleRef.current) {
      return;
    }

    toggleRef.current.disabled = true;

    try {
      await callback();
    } catch (err) {
      const isConsentRequiredError = (
        err instanceof Error && err.message.includes('consent_required')
      );

      if (err !== NoTokensProvidedError && !isConsentRequiredError) {
        Sentry.captureException(err);
      }

      toggleRef.current.checked = !isChecked;
    }

    toggleRef.current.disabled = false;
  }, []);

  useEffect(() => { handleOnLoad(); }, [handleOnLoad]);

  return (
    <div className="flex flex-col sm:flex-row items-center justify-center gap-4 sm:gap-6">
      {/* {autoJoinStatus !== null && (
        <div className="flex items-center gap-3">
          <span>
            Auto-join meetings
          </span>
          <div className="w-10 h-5">
            <Radio
              ref={autoJoinToggleRef}
              onChange={(e) => {
                const isChecked = e.target.checked;

                handleToggle(
                  isChecked,
                  autoJoinToggleRef,
                  async () => {
                    const tokens = new Tokens();

                    const ssoToken = await getSSOToken();

                    if (!ssoToken) {
                      throw new Error('could not get SSO token');
                    }

                    await authClient.behalfof(tokens, ssoToken, autoJoinPopUpConfig);

                    if (!tokens.isValid()) {
                      const params = new URLSearchParams(await launchConsentPopup(autoJoinPopUpConfig));

                      const code = params.get('code');

                      const error = params.get('error');

                      if (!code || error) {
                        throw new Error(error || 'code param is missing');
                      }

                      await authClient.code(tokens, code, autoJoinPopUpConfig);
                    }

                    const { accessToken, refreshToken } = tokens;

                    if (!ssoToken || !accessToken || !refreshToken) {
                      throw NoTokensProvidedError;
                    }

                    if (isChecked) {
                      await enableAutoJoin(ssoToken, accessToken, refreshToken);
                    } else {
                      await disableAutoJoin(ssoToken, accessToken);
                    }
                  },
                );
              }}
              defaultChecked={autoJoinStatus}
            />
          </div>
        </div>
      )} */}
      {processingStatus !== null && (
        <div className="flex items-center gap-3">
          <span>
            Analyze meetings
          </span>
          <div className="w-10 h-5">
              <Radio
                ref={processingToggleRef}
                onChange={(e) => {
                  const isChecked = e.target.checked;

                  handleToggle(
                    isChecked,
                    processingToggleRef,
                    async () => {
                      const ssoToken = await getSSOToken();

                      if (!ssoToken) {
                        throw new Error('could not get SSO token');
                      }

                      await updateProcessingStatus(isChecked, ssoToken);

                      setProcessingStatus(isChecked);
                    },
                  );
                }}
                defaultChecked={processingStatus}
              />
          </div>
        </div>
      )}
    </div>
  );
};

export default Settings;
